import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'

const CouponsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/coupons`,
  rowsPath: 'coupons',
  rowClickable: true,
  columns: [
    {
      label: 'coupons.coupon-title',
      field: 'name',
    },
    {
      label: 'coupons.coupon-code',
      field: 'code',
      center: true,
    },
    {
      label: 'coupons.redeems',
      field: 'redeems',
      center: true,
    },
    {
      label: 'coupons.discount',
      field: 'discount',
    },
    {
      label: 'coupons.start',
      field: 'valid_from_formatted',
    },
    {
      label: 'coupons.end',
      field: 'valid_until_formatted',
    },
    {
      label: 'forms.actions',
      field: 'actions',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
  ],
  filters: [
    {
      label: 'coupons.coupon-title',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'coupons.coupon-code',
      query: 'code',
      colLength: 3,
    },
    {
      label: 'coupons.discount-value',
      query: 'discount_value',
      colLength: 3,
      append: true,
      appendQuery: 'discount_type',
      appendType: 'select',
      appendOptions: [{ value: '0', text: '%' }, { value: '1', text: 'fixed' }],
    },
    {
      label: 'coupons.start',
      query: 'valid_from',
      type: 'date',
      colLength: 3,
    },
    {
      label: 'coupons.end',
      query: 'valid_until',
      type: 'date',
      colLength: 3,
    },
  ],
}

export default CouponsTableConfig
