import $http from '@/libs/axios'

class CouponsApi {
  static async delete(id: number) {
    return (await $http.delete(`coupons/${id}`)).data
  }

  static async list() {
    return (await $http.get('coupons')).data
  }

  static async process(data: any) {
    return (await $http.post('coupons', data)).data
  }

  static async getById(id: string) {
    return (await $http.get(`coupons/${id}`)).data
  }

  static convertCouponToString(coupon: any, currency: string) {
    if (!coupon) return '-'
    return `${coupon?.code} (${coupon?.discount_value} ${coupon?.discount_type === 'fixed' ? currency : '%'})`
  }

  static getCouponDiscount(coupon: any, currency: string) {
    if (!coupon) return '-'
    return `${coupon?.discount_value}${coupon?.discount_type === 'fixed' ? currency : '%'}`
  }
}

export default CouponsApi
