<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="qr-modal"
    :title="$t(editMode?'coupons.edit-coupon':'coupons.add-coupon')"
    @change="loading ? null : $emit('input', $event)"
  >
    <coupon-form
      ref="couponForm"
      :coupon="coupon"
      :edit="editMode"
      @error="loading=false"
      @processed="$emit('processed')"
    />
    <template #actions>
      <div class="w-100">
        <b-button
          variant="dark"
          class="float-right"
          :disabled="loading"
          @click="submit()"
        >
          <b-spinner
            v-if="loading"
            class="mx-1"
            small
            label="submitting..."
          />
          {{ $t('forms.save') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import CouponForm from './CouponForm'

export default {
  name: 'ProcessCouponModal',
  components: {
    OkModal,
    CouponForm,
    BButton,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    coupon: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    editMode() {
      return !!this.coupon?.id
    },
  },
  methods: {
    async submit() {
      this.loading = true
      await this.$refs.couponForm.save()
      this.loading = false
    },
  },

}
</script>
