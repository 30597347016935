<template>
  <validation-observer ref="form">
    <error-alert :error="error" />
    <slide-y-up-transition>
      <b-row class="coupon">
        <b-col
          sm="12"
          md="6"
        >
          <ok-text-input
            v-model="form.name"
            :is-required="true"
            :read-only="readOnly"
            :label="$t('coupons.coupon-title')"
          />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <ok-text-input
            v-model="form.code"
            :read-only="readOnly"
            :is-required="true"
            rules="max:14"
            :label="$t('coupons.coupon-code')"
          />

        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <ok-text-input
            v-model="form.discount_value"
            :is-required="true"
            :show-append="true"
            :read-only="readOnly"
            :rules="discountRule"
            :label="$t('coupons.discount-value')"
          >
            <template slot="append">
              <b-form-select
                v-model.trim="form.discount_type"
                :disabled="readOnly"
                style="border-radius: 0"
              >
                <b-form-select-option
                  value="0"
                  v-text="'%'"
                />
                <b-form-select-option
                  value="1"
                  v-text="currency"
                />
              </b-form-select>
            </template>
          </ok-text-input>
        </b-col>

        <b-col
          sm="12"
          md="6"
        >
          <ok-text-input
            v-model="form.redeems_limit"
            :read-only="readOnly"
            :is-required="true"
            rules="min_value:1"
            :label="$t('coupons.redeems')"
          >
            <template slot="footer">
              <small class="d-block">{{ $t('coupons.redeems-hint') }}</small>
            </template>
          </ok-text-input>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <ok-date-time-picker
            v-model="form.valid_from"
            :disabled="readOnly"
            :rules="'required'"
            type="datetime"
            :label="$t('coupons.start')"
            :min-date="edit?form.valid_from:minDate"
          />
        </b-col>

        <b-col
          sm="12"
          md="6"
        >
          <ok-date-time-picker
            v-model="form.valid_until"
            :disabled="!canEditDates"
            :rules="'required'"
            type="datetime"
            :label="$t('coupons.end')"
            :min-date="form.valid_from"
          />
        </b-col>
        <b-col
          v-if="editMode"
          sm="12"
          md="12"
        >
          <div class="email-notification">
            <div class="d-flex justify-content-between align-items-center">
              <legend style="padding: 0">
                {{ $t('coupons.enable-notification') }}
              </legend>
              <b-form-checkbox
                v-model="form.enable_notification"
                :disabled="readOnly"
                name="enable_notification"
                switch
              />
            </div>
            <ok-text-input
              v-if="form.enable_notification"
              v-model="form.supervisor_email"
              :read-only="readOnly"
              :is-required="true"
              :label="$t('fields.email')"
            />
          </div>
        </b-col>
      </b-row>
    </slide-y-up-transition>
  </validation-observer>

</template>

<script>
import {
  BRow, BCol, BFormSelect, BFormSelectOption, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import OkDateTimePicker from '@/@bya3/components/inputs/OkDateTimePicker'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import CouponsApi from '@/api/coupons-api'
import dayjs from 'dayjs'

export default {
  name: 'CouponForm',
  components: {
    ErrorAlert,
    OkTextInput,
    OkDateTimePicker,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    ValidationObserver,
  },
  props: {
    coupon: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: null,
      form: {},
      minDate: dayjs().add(10, 'm').format('YYYY-MM-DD[T]HH:mm:ssZ'),
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency'] ?? ''
    },
    readOnly() {
      return this.edit && (this.form?.active || this.form?.expired)
    },
    canEditDates() {
      if (!this.edit) return true
      return !this.form?.expired
    },
    discountRule() {
      return (this.form?.discount_type === '0') ? 'min_value:0.01|max_value:100' : 'min_value:0.01'
    },
  },
  watch: {
    coupon() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form = this.edit ? this.coupon : this.getInitialForm()
    },
    getInitialForm() {
      return {
        multiple_allowance: 0,
        redeems_limit: 1,
        name: '',
        code: '',
        valid_from: this.minDate,
        valid_until: '',
        discount_value: 0,
        discount_type: '0',
        supervisor_email: '',
        enable_notification: false,
      }
    },
    async save() {
      this.error = null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.$emit('error')
        throw Error('Failed')
      }
      const form = { ...this.form }
      if (this.coupon?.id)form.id = this.coupon.id
      await CouponsApi.process(form)
        .then(() => {
          this.$emit('processed')
          this.$notifySuccess(this.$t(`coupons.${this.edit ? 'update' : 'add'}-success`))
        })
        .catch(e => {
          this.error = e
          this.$emit('error')
          throw Error('Failed')
        })
    },
  },
}
</script>
