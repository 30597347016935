<template>
  <b-dropdown
    variant="link"
    dropup
    toggle-class="text-decoration-none"
    :right="$store.getters['appConfig/isRTL']"
    no-caret
  >
    <template v-slot:button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="text-body align-middle mr-25"
      />
    </template>
    <slot />

  </b-dropdown>
</template>

<script>
import { BDropdown } from 'bootstrap-vue'

export default {
  name: 'CustomActions',
  components: {
    BDropdown,
  },
}
</script>
