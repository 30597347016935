<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('coupons.title')"
      :config="config"
      @update:total="$store.commit('coupons/SET_COUPONS_COUNT', $event)"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openAddModal()"
        >
          {{ $t('coupons.add-coupon') }}
        </b-button>
      </template>
      <template
        slot="code-column"
        slot-scope="{props}"
      >
        <b-badge variant="light-primary">
          {{ props.row.code }}
        </b-badge>
      </template>
      <template
        slot="discount-column"
        slot-scope="{props}"
      >
        <b-badge variant="light-info">
          <span v-if="props.row.discount_type==='fixed'">
            {{ `${props.row.discount_value} ${currency}` }}
          </span>
          <span v-else>
            {{ `${props.row.discount_value}%` }}
          </span>
        </b-badge>
      </template>
      <template
        slot="actions-column"
        slot-scope="{props}"
      >
        <custom-actions>
          <template v-for="action in actions">
            <b-dropdown-item
              v-if="checkActionCondition(action.action,props.row)"
              :key="action.action"
              @click.stop="handleAction(props.row,action.action)"
            >
              <feather-icon
                :icon="action.icon"
                class="mr-50"
              />
              <span>{{ $t(action.label) }}</span>
            </b-dropdown-item>
          </template>

        </custom-actions>
      </template>
    </ok-table>
    <process-coupon-modal
      ref="user-modal"
      v-model="couponModal"
      :edit="editCoupon"
      :coupon="coupon"
      @processed="resetModalForm"
    />
  </div>
</template>

<script>
import {
  BButton, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import CustomActions from '@/@bya3/components/tables/ok-table/components/CustomActions'
import CouponsApi from '@/api/coupons-api'
import CouponsTableConfig from './config/coupons-table-config'
import ProcessCouponModal from './components/ProcessCouponModal'

export default {
  components: {
    CustomActions,
    ProcessCouponModal,
    BBadge,
    BButton,
    BDropdownItem,
    OkTable: () => import(/* webpackChunkName: "ok-table" */ '@/@bya3/components/tables/ok-table/OkTable'),
  },
  data() {
    return {
      config: CouponsTableConfig,
      coupon: null,
      editCoupon: false,
      couponModal: false,
      isLoading: false,
      showTooltipBadge: true,
      actions: [
        { icon: 'Edit2Icon', label: 'forms.edit', action: 'Edit' },
        { icon: 'DownloadIcon', label: 'coupons.download-badge', action: 'Download' },
        { icon: 'Trash2Icon', label: 'forms.delete', action: 'Delete' },
      ],
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency'] ?? ''
    },
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    checkActionCondition(type, row) {
      switch (type) {
        case 'Edit': return !row.expired
        case 'Delete': return row.deletable
        default: return true
      }
    },
    handleAction(data, type) {
      this[`handle${type}`](data)
    },
    handleEdit(params) {
      this.coupon = params
      this.editCoupon = true
      this.couponModal = true
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('coupons.delete-coupon-message'), {
        preConfirm: () => CouponsApi.remove(data.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    resetModalForm() {
      this.$refs.datatable.reload()
      this.coupon = null
      this.couponModal = false
    },
    openAddModal() {
      this.coupon = null
      this.couponModal = true
    },
  },
}
</script>
