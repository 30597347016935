<template>
  <ok-text-input v-bind="$props">
    <flat-picker
      v-if="!refreshingConfig"
      :value="value"
      class="form-control"
      :class="`flat-picker-${size}`"
      :config="pickerConfig"
      :placeholder="label || placeholder"
      :disabled="disabled"

      @input="$emit('input', $event)"
    />
  </ok-text-input>
</template>

<script>
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import okTextInputProps from '@/@bya3/components/inputs/utils/ok-text-input-props'

import(/* webpackChunkName: "date-picker" */ '@core/scss/vue/libs/vue-flatpicker.scss')

export default {
  name: 'OkDateTimePicker',
  components: {
    OkTextInput,
    flatPicker: () => import(/* webpackChunkName: "date-picker" */ 'vue-flatpickr-component'),
  },
  props: {
    ...okTextInputProps,
    type: {
      type: String,
      default: 'date',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refreshingConfig: false,
    }
  },
  computed: {
    isDateTime() {
      return this.type === 'datetime'
    },
    isTime() {
      return this.type === 'time'
    },
    dateFormat() {
      switch (this.type) {
        case 'time': return 'H:i'
        case 'date': return 'Y-m-d'
        default: return 'Y-m-d H:i'
      }
    },
    pickerConfig() {
      return {
        enableTime: this.isDateTime || this.isTime,
        noCalendar: this.isTime,
        dateFormat: this.dateFormat,
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: 'en',
        disableMobile: true,
      }
    },
  },
  watch: {
    pickerConfig() {
      this.refreshingConfig = true
      this.$nextTick(() => { this.refreshingConfig = false })
    },
  },
}
</script>

<style lang="scss">

.flat-picker-lg {
  height: 46px;
  padding: 14px 16px 13px;
  line-height: 1.19;
  font-size: 16px;
}
</style>
